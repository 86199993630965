body {
  -webkit-font-smoothing: subpixel-antialiased;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-x: hidden;
}

.text-center {
  text-align: center;
}

.error {
  margin-bottom: 16px !important;
}
.fb-hover {
  overflow: hidden;
}
.fb-hover:hover:after {
  display: block
}
.fb-hover::after{
  content: '""';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.05;
  display: none;
  z-index: 0;
  border-radius: inherit
}
.fb-hover:active:after{
  opacity: 0.15;
}
.cursor-pointer {
  cursor: pointer;
}

.tox-statusbar__branding { display: none; }
